<template>
	<v-container
			fluid
			fill-height
	>
		<v-img id="bg"></v-img>

		<v-dialog
			v-model="show"
			max-width="400"
			persistent
			hide-overlay
		>
			<v-card class="elevation-12">
				<v-card-title>
					Pin  Code
				</v-card-title>

				<v-divider />

				<ValidationObserver
					ref="code"
					@submit.prevent="submitCode"
				>
					<form autocomplete="off">
						<v-card-text align="center">
							<v-form v-on:submit.prevent>
								<CodeInput
									autoFocus
									:loading="false"
									class="code-input"
									v-on:complete="onComplete"
									v-model="code"
									type="password"
								/>

								<VTextFieldValidation
									rules="required"
									v-model="code"
									label="Code"
									type="password"
									onSubmit="return false"
									v-on:keyup="checkCodeSubmit"
									v-if="false"
								/>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<Button
								type="submit"
								:loading="loading"
								:disabled="loading"
							>Authenticate</Button>
						</v-card-actions>
					</form>
				</ValidationObserver>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import VueCookies from 'vue-cookies'
	import VTextFieldValidation from '@/components/validation/VTextField.vue'
	import { mapActions } from 'vuex'
	import Vue from "vue"
	import CodeInput from "vue-verification-code-input"

	export default {
		components: {
			VTextFieldValidation,
			CodeInput,
		},
		props: [`authenticated`],
		name: `LoginCode`,
		data() {
			return {
				env: process.env,
				code: "",
				loading: false,
				show: true,
			}
		},
		methods: {
			...mapActions([`setAdmin`]),
			success(data) {
				if (data.result.token !== null) VueCookies.set(`token`,data.result.token, `30d`)
				this.setAdmin(data.result)
				this.$emit(`authenticated`, true);
				this.$router.replace({ name: `Dashboard` });
			},
			checkCodeSubmit(key) {
				if (key.key === `Enter`) this.submitCode()
			},
			onComplete(v) {
				this.code = v
				this.submitCode()
			},
			submitCode() {
				if (!this.code) return
				this.$refs.code.validate().then(valid => {
					if (!valid) return
					this.loading = true

					this.$httpInt.post(`/v2/auth/code`, { code: this.code })
						.then(res => {
							this.success(res.data)
							this.$httpInt.get(`/v2/system/config`)
								.then(res => {
									Vue.prototype.$appUrl = res.data.result.app.url
								})
						})
						.finally(() => this.loading = false)
				})
			},
		},
		mounted() {
			document.body.querySelector('.code-input input').focus()
		}
	}
</script>

<style lang="scss">
	#bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-size: cover !important;
		background: grey url('../assets/wdht-background.jpg') no-repeat center center;
	}

	.code-input input {
		font-family: $body-font-family !important;
	}
</style>